// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './hooks/ScrollToTop';
import AppRoutes from './AppRoutes';
import WelcomePopup from './components/WelcomePopup';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import data from './data/data.json';
import CTASection from './components/CTASections';
import WelcomeCTASection from './components/WelcomeCTASection';

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'Namotura, sans-serif',
    },
    h2: {
      fontFamily: 'Namotura, sans-serif',
    },
    h3: {
      fontFamily: 'Namotura, sans-serif',
    },
    fontFamily: 'Space-Grotesk',
  },
});

function AppContent() {
  const [blogs, setBlogs] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const sortedBlogs = data.sort((a, b) => b.id - a.id);
    setBlogs(sortedBlogs);
    
    // Check if popup has been shown before
    const hasPopupShown = localStorage.getItem('popupShown');
    
    // Show popup if it hasn't been shown before
    if (!hasPopupShown) {
      // Add a small delay to show the popup after page load
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 1500);
      
      return () => clearTimeout(timer);
    }
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
    // Set localStorage even if they close without submitting
    localStorage.setItem('popupShown', 'true');
  };

  return (
    <>
      <AppRoutes blogs={blogs} />
      {location.pathname !== '/contact' && <CTASection />}
      {showPopup && <WelcomePopup onClose={handleClosePopup} />}
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Navbar />
        <AppContent />
        <WelcomeCTASection />
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;