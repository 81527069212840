import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Menu, MenuItem, ListItemIcon, Box, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ArticleIcon from '@mui/icons-material/Article';
import SecurityIcon from '@mui/icons-material/Security';
import CloudIcon from '@mui/icons-material/Cloud';
import BuildIcon from '@mui/icons-material/Build';
import PhoneIcon from '@mui/icons-material/Phone';
import CallEndIcon from '@mui/icons-material/CallEnd';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import Logo from './assets/transparent-logo.png';
import { ComputerTwoTone, DesignServicesSharp, ManageAccountsTwoTone } from '@mui/icons-material';
import { styled, keyframes } from '@mui/system';
import FloatingCTA from './FloatingCTA';

// Keyframes for pulsing effect
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const StyledAppBar = styled(AppBar)(({ hide }) => ({
  backgroundColor: 'black',
  transition: 'transform 0.3s ease',
  transform: hide ? 'translateY(-100%)' : 'translateY(0)',
}));

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledIconButton = styled(IconButton)({
  color: '#FFD700',
  transition: 'color 0.3s ease',
  '&:hover': {
    color: '#FFFFFF',
  },
});

const PhoneButton = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: '#FFD700',
  color: '#000000',
  fontWeight: 'bold',
  padding: isMobile ? '5px 10px' : '8px 16px',
  borderRadius: '20px',
  fontFamily: 'Poppins, sans-serif',
  display: 'flex',
  alignItems: 'center',
  gap: isMobile ? '4px' : '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  transition: 'all 0.3s ease',
  fontSize: isMobile ? '0.8rem' : '1rem',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    cursor: 'pointer',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
    transform: 'translateY(-2px)',
  },
  '&:active': {
    backgroundColor: '#F0F0F0',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    transform: 'translateY(0)',
  },
}));

const PhoneIconRotate = styled(PhoneIcon)(({ isMobile }) => ({
  transition: 'transform 0.3s ease',
  fontSize: isMobile ? '1rem' : '1.25rem',
  '&:hover': {
    transform: 'rotate(15deg)',
  },
}));

const StyledDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: 240,
    backgroundColor: '#121212',
    color: '#FFD700',
    transition: 'all 0.3s ease',
    fontFamily: 'Poppins, sans-serif',
  },
});

const StyledListItem = styled(ListItem)({
  transition: 'background-color 0.3s ease, transform 0.3s ease',
  '&:hover': {
    backgroundColor: '#1c1c1c',
    transform: 'scale(1.05)',
  },
});

const StyledMenuItem = styled(MenuItem)({
  transition: 'background-color 0.3s ease, transform 0.3s ease',
  '&:hover': {
    backgroundColor: '#1c1c1c',
    transform: 'scale(1.05)',
  },
});

// New component for animated phone number
const AnimatedPhoneNumber = styled(Box)({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '0%',
    height: '2px',
    bottom: '-2px',
    left: '0',
    backgroundColor: 'black',
    transition: 'width 0.3s ease',
  },
  '&:hover::after': {
    width: '100%',
  },
});

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hideNavbar, setHideNavbar] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [isCallHovered, setIsCallHovered] = useState(false);
  
  // Theme and media queries
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraSmall = useMediaQuery('(max-width:400px)');
  
  // Replace with your actual phone number
  const phoneNumber = "757-559-1231";
  const formattedPhoneNumber = isExtraSmall ? "757-559-1231" : "757-559-1231";

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleServicesClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleServicesClose = () => {
    setAnchorEl(null);
  };

  const handleLinkClick = () => {
    setMobileOpen(false);
    handleServicesClose();
  };

  // Handle phone call
  const handlePhoneCall = () => {
    window.location.href = `tel:${phoneNumber.replace(/-/g, '')}`;
  };

  // Scroll event handler to show/hide navbar
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > prevScrollY && currentScrollY > 100) {
        setHideNavbar(true);
      } else {
        setHideNavbar(false);
      }
      setPrevScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollY]);

  const drawer = (
    <List sx={{ fontFamily: 'Poppins, sans-serif' }}>
      <StyledListItem button component={Link} to="/" onClick={handleLinkClick}>
        <ListItemIcon>
          <HomeIcon sx={{ color: 'gold' }} />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </StyledListItem>
      <StyledListItem button onClick={handleServicesClick}>
        <ListItemIcon>
          <BusinessCenterIcon sx={{ color: 'gold' }} />
        </ListItemIcon>
        <ListItemText primary="Services" />
        <ExpandMoreIcon />
      </StyledListItem>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleServicesClose}
        PaperProps={{
          style: {
            backgroundColor: '#121212',
            color: 'gold',
            fontFamily: 'Poppins, sans-serif',
          },
        }}
      >
        <StyledMenuItem component={Link} to="/services" onClick={handleLinkClick}>
          All Services
        </StyledMenuItem>
        <StyledMenuItem component={Link} to="/services/it-services" onClick={handleLinkClick}>
          <ListItemIcon>
            <BuildIcon sx={{ color: 'gold' }} />
          </ListItemIcon>
          IT Services
        </StyledMenuItem>
        <StyledMenuItem component={Link} to="/services/cloud-services" onClick={handleLinkClick}>
          <ListItemIcon>
            <CloudIcon sx={{ color: 'gold' }} />
          </ListItemIcon>
          Cloud Services
        </StyledMenuItem>
        <StyledMenuItem component={Link} to="/services/computer-repair-services" onClick={handleLinkClick}>
          <ListItemIcon>
            <ComputerTwoTone sx={{ color: 'gold' }} />
          </ListItemIcon>
          Computer Repair
        </StyledMenuItem>
        <StyledMenuItem component={Link} to="/services/cybersecurity-services" onClick={handleLinkClick}>
          <ListItemIcon>
            <SecurityIcon sx={{ color: 'gold' }} />
          </ListItemIcon>
          Cybersecurity Services
        </StyledMenuItem>
        <StyledMenuItem component={Link} to="/services/voip-services" onClick={handleLinkClick}>
          <ListItemIcon>
            <PhoneIcon sx={{ color: 'gold' }} />
          </ListItemIcon>
          VoIP Services
        </StyledMenuItem>
        <StyledMenuItem component={Link} to="/services/managed-it-services" onClick={handleLinkClick}>
          <ListItemIcon>
            <DesignServicesSharp sx={{ color: 'gold' }} />
          </ListItemIcon>
          Managed IT Services
        </StyledMenuItem>
        <StyledMenuItem component={Link} to="/services/technology-procurement-services" onClick={handleLinkClick}>
          <ListItemIcon>
            <ManageAccountsTwoTone sx={{ color: 'gold' }} />
          </ListItemIcon>
          TPM Services
        </StyledMenuItem>
      </Menu>
      <StyledListItem button component={Link} to="/about" onClick={handleLinkClick}>
        <ListItemIcon>
          <InfoIcon sx={{ color: 'gold' }} />
        </ListItemIcon>
        <ListItemText primary="About Us" />
      </StyledListItem>
      <StyledListItem button component={Link} to="/contact" onClick={handleLinkClick}>
        <ListItemIcon>
          <ContactMailIcon sx={{ color: 'gold' }} />
        </ListItemIcon>
        <ListItemText primary="Contact" />
      </StyledListItem>
      <StyledListItem button component={Link} to="/blog" onClick={handleLinkClick}>
        <ListItemIcon>
          <ArticleIcon sx={{ color: 'gold' }} />
        </ListItemIcon>
        <ListItemText primary="Blog" />
      </StyledListItem>
      <StyledListItem button component={Link} to="/privacy-policy" onClick={handleLinkClick} sx={{ mt: 'auto', borderTop: '1px solid gold' }}>
        <ListItemIcon>
          <PrivacyTipIcon sx={{ color: 'gold' }} />
        </ListItemIcon>
        <ListItemText primary="Privacy Policy" />
      </StyledListItem>
    </List>
  );

  return (
    <>
      <StyledAppBar position="sticky" hide={hideNavbar}>
        <StyledToolbar>
          <Link to="/">
            <img 
              src={Logo} 
              alt="All Repair PC" 
              style={{ height: isMobile ? '80px' : '120px', width: 'auto' }} 
            />
          </Link>
          
          <Box sx={{ display: 'flex', alignItems: 'center', gap: isMobile ? 1 : 2 }}>
            <Tooltip title="Call us now for immediate assistance!" arrow placement="bottom">
              <PhoneButton 
                onClick={handlePhoneCall}
                onMouseEnter={() => setIsCallHovered(true)}
                onMouseLeave={() => setIsCallHovered(false)}
                sx={{ animation: isCallHovered ? 'none' : `${pulse} 2s infinite ease-in-out` }}
                isMobile={isMobile}
              >
                {isCallHovered ? (
                  <CallEndIcon fontSize={isMobile ? "small" : "medium"} sx={{ transform: 'rotate(135deg)' }} />
                ) : (
                  <PhoneIconRotate fontSize={isMobile ? "small" : "medium"} isMobile={isMobile} />
                )}
                {isExtraSmall ? null : (
                  <AnimatedPhoneNumber>
                    {formattedPhoneNumber}
                  </AnimatedPhoneNumber>
                )}
              </PhoneButton>
            </Tooltip>
            
            <StyledIconButton edge="end" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon style={{ fontSize: isMobile ? "30px" : "40px" }}/>
            </StyledIconButton>
          </Box>
        </StyledToolbar>
      </StyledAppBar>
      
      <nav>
        <StyledDrawer
          anchor="right"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
        </StyledDrawer>
      </nav>

      {/* Render the floating CTA when the navbar is hidden */}
      {hideNavbar && <FloatingCTA phoneNumber={phoneNumber} />}
    </>
  );
};

export default Navbar;
